import * as React from "react"
import Navigation from './navigation'


const Header = ({noContact, heroVideo}) => {

  return (
    <header>
      <Navigation noContact={noContact} />
    </header>
  )
}

export default Header
