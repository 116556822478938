/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "../components/footer"
import styled from 'styled-components'
import Breadcrumb from "./breadcrumb"

const Main = styled.main`
  margin-top: ${props => (props.inverted ? 0 : (props.marginTop ? props.marginTop-100 : 50))}px;

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    margin-top: ${props => (props.inverted ? 0 : (props.marginTop ? props.marginTop : 100))}px;
  } 
  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    margin-top: ${props => (props.inverted ? 0 : (props.marginTop ? props.marginTop : 150))}px;
  }

`

const Layout = ({ marginTop, marginBtm, children, inverted, noContact, breadcrumb, heroVideo }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header heroVideo={heroVideo} inverted={inverted} noContact={noContact} siteTitle={data.site.siteMetadata?.title || `Title`} />
        {breadcrumb && 
          <Breadcrumb breadcrumb={breadcrumb} inverted={inverted}/>
        }
      <Main marginTop={marginTop} inverted={inverted}>
        {children}
      </Main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  marginTop: PropTypes.number,
  marginBtm: PropTypes.number,
}

export default Layout
