import React from 'react'
import PropTypes from "prop-types"

import styled from "styled-components"
import * as variables from '../utilities/style-variables.js';


const ContentSection = styled.section`
  position: relative;
  margin: 50px 0;
  padding: 50px 0;
  height: ${props => props.heroSection ? '100vh' : 'auto'} ;
  background-color: ${props => props.bgDark ? variables.color_brand_bg_dark : props.bgLight ? variables.color_brand_bg_light : "transparent"};
  overflow: hidden;

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    margin: ${props => props.heroSection ? '0' : '80px 0 80px'};
    padding: ${props => props.heroSection ? '0' : '80px 0 80px'};

    &::after {
      content:'';
      position: absolute;
      display: ${props => props.cutOff ? 'block' : 'none'};
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100px;
      border-left: 4000px solid transparent;
      border-bottom: 100px solid ${variables.color_white};
    }
  } 
`

const Section = ({ bgDark, bgLight, children, inverted, cutOff, heroSection}) => {
  return (
    <>
      <ContentSection heroSection={heroSection} bgDark={bgDark} bgLight={bgLight} inverted={inverted} cutOff={cutOff}>
        <div className="container">
          {children}
        </div>
      </ContentSection>
    </>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
}



export default Section
